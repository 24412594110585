export const apiBaseURL = () => {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    return 'http://localhost:3000';
  } else if (hostname.indexOf('iflow21.com') > -1) {
    return `${window.location.protocol}//api-${window.location.host}`;
  } else {
    return `${window.location.protocol}//api.${window.location.host}`;
  }
};
