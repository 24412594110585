import React from 'react';
import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  infoContainer: {
    display: 'flex',
  },
  infoLabel: {
    width: '100px',
  },
  infoContent: {
    paddingTop: '5px',
  },
});

interface Props {
  label: string;
  value?: string;
}
function TypographyRow({ label, value }: Props) {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} md={6} className={classes.infoContainer}>
        <Typography variant="h6" component="div" className={classes.infoLabel}>
          {label}
        </Typography>
        <Typography variant="body1" component="div" className={classes.infoContent}>
          {value && value}
        </Typography>
      </Grid>
    </>
  );
}

export default TypographyRow;
