import Listado from '../../components/Listado';
import { Filter } from '../../components/Listado/interfaces';
import { useTitle } from '../../components/Main';
import { OrderStatus } from '../../interfaces/interfaces';

import { useFetchOrders } from '../../utilities/hooks/useFetchOrders';

const columnsAdmin: any = {
  columns: [
    { name: 'id', label: 'Id', minWidth: 90 },
    { name: 'storeId', label: 'Store', minWidth: 90 },
    { name: 'trackingId', label: 'Tracking Id', minWidth: 90 },
    { name: 'status', label: 'Estado', minWidth: 90 },
    { name: 'orderCreateAt', label: 'F Creacion', minWidth: 90 },
    { name: 'created', label: 'Creado', minWidth: 90 },
    { name: 'paid', label: 'Pagado', minWidth: 90 },
    { name: 'packed', label: 'Empaquetado', minWidth: 90 },
    { name: 'fulfill', label: 'Fulfill', minWidth: 90 },
    { name: 'cancelled', label: 'Cancelado', minWidth: 90 },
  ],
  actions: {
    edit: { name: 'edit', label: 'Editar', minWidth: 10, link: '/order/edit/' },
    view: { name: 'view', label: 'Ver', minWidth: 10, link: '/order/' },
  },
};

const OrdersPage = () => {
  const { setTitle } = useTitle();
  setTitle('Ordenes');

  const { orders, pagination, loading, handleChangeFilters, handleChangePagination } = useFetchOrders();

  const filters: Filter[] = [
    { name: 'id', label: 'Id', type: 'text' },
    { name: 'trackingId', label: 'Tracking Id', type: 'text' },
    { name: 'storeId', label: 'Store', type: 'text' },

    {
      name: 'status',
      label: 'Estado',
      type: 'select',
      options: [
        { value: 'CREATED', label: 'CREATED' },
        { value: 'PROCESSED', label: 'PROCESSED' },
        { value: 'NOT_PROCESSED', label: 'NOT_PROCESSED' },
        { value: 'NO_IFLOW', label: 'NO_IFLOW' },
        { value: 'PACKED', label: 'PACKED' },
        { value: 'FULFILLED', label: 'FULFILLED' },
        { value: 'CANCELLED', label: 'CANCELLED' },
        { value: 'NOT_CANCELLED', label: 'NOT_CANCELLED' },
        { value: 'REVERSE', label: 'REVERSE' },
      ],
    },
    { name: 'created_at_from', label: 'Creado desde', type: 'date' },
    { name: 'created_at_to', label: 'Creado hasta', type: 'date' },
  ];

  return (
    <div>
      {
        <Listado
          loading={loading}
          filters={filters}
          listadoColumnas={columnsAdmin}
          rows={orders || []}
          pagination={pagination}
          handleChangeFilters={handleChangeFilters}
          handleChangePagination={handleChangePagination}
        />
      }
    </div>
  );
};

export default OrdersPage;
