import React from 'react';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { Filter } from './interfaces';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormSelect from '../Forms/Select/Select';

const theme = createTheme();

const useStyles = makeStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});
interface Props {
  filter: Filter;
  onHandleChange: (key: string, value: string | number) => void;
}

export default function ListadoFilters(props: Props) {
  const classes = useStyles();
  const { filter, onHandleChange } = props;
  switch (filter.type) {
    case 'text':
      return (
        <FormControl className={classes.formControl}>
          <TextField
            id={filter.name}
            label={filter.label}
            onChange={(e) => (filter.callback ? filter.callback(e.target.value) : onHandleChange(filter.name, e.target.value))}
            style={{ marginLeft: '10px', marginBottom: '20px' }}
          />
        </FormControl>
      );
    case 'select':
      if (!filter.options) return null;
      return <FormSelect select={{ name: filter.name, label: filter.label, options: filter.options }} onHandleChange={onHandleChange} />;
    case 'date':
      return (
        <FormControl className={classes.formControl}>
          <TextField
            id={filter.name}
            name={filter.name}
            label={filter.label}
            type="date"
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => (filter.callback ? filter.callback(e.target.value) : onHandleChange(filter.name, e.target.value))}
            style={{ marginLeft: '10px' }}
          />
        </FormControl>
      );
    default:
      return null;
  }
}
