import { useEffect, useState } from 'react';
import { RequestOptionsListado } from '../../../components/Listado/interfaces';
import { getStatus } from '../../../services/iFlowServices';

const transformData = (status: any) => {
  console.log('🚀 ~ file: index.tsx:6 ~ transformData ~ status', status);
  return status.map((state: any) => {
    return {
      ...state,
      finalState: state.finalState === true ? 'SI' : 'NO',
    };
  });
};

export const useFetchStatus = () => {
  const [data, setData] = useState();
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchOrders = async (options?: RequestOptionsListado) => {
    setLoading(true);
    const response = await getStatus(options);
    const data = transformData(response.docs);
    console.log('🚀 ~ file: index.tsx:23 ~ fetchOrders ~ data', data);
    setData(data);
    setPagination(response);
    setLoading(false);
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  const handleChangeFilters = async (options?: RequestOptionsListado) => {
    fetchOrders(options);
  };

  const handleChangePagination = async (options?: RequestOptionsListado) => {
    fetchOrders(options);
  };

  return { data, pagination, loading, handleChangeFilters, handleChangePagination };
};
