import React from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
import { AlertContextProvider } from './utilities/context/AlertContextProvider';
import { AuthContextProvider } from './utilities/context/AuthContextProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#ff9651',
      main: '#f16522',
      dark: '#b73400',
      contrastText: '#fff',
    },
    secondary: {
      light: '#c8f457',
      main: '#94c11f',
      dark: '#619000',
      contrastText: '#000',
    },
  },
});

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Router>
          <AlertContextProvider>
            <AuthContextProvider>
              <PublicRoutes />
              <PrivateRoutes />
            </AuthContextProvider>
          </AlertContextProvider>
        </Router>
      </ThemeProvider>
    </>
  );
}

export default App;
