import * as axios from 'axios';
import { RequestActivation, ResponseToken } from '../../interfaces/interfaces';
import { apiBaseURL } from '../../services/settingsService';

export const getTnToken = (tnCode: string): Promise<axios.AxiosResponse<ResponseToken>> => {
  return axios.default.get(`${apiBaseURL()}/seller/getToken?tnCode=${tnCode}`);
};

export const activateSeller = (requestActivation: RequestActivation): Promise<axios.AxiosResponse<boolean>> => {
  return axios.default.post(`${apiBaseURL()}/seller/activate`, requestActivation);
};
