import Loading from '../../components/Loading';
import { useTitle } from '../../components/Main';
import { makeStyles } from '@mui/styles';
import ModalDialog from '../../components/ModalDialog';
import { saveManualOrder, saveReverseLogistic } from '../../services/iFlowServices';
import { useState } from 'react';
import { useAlertContext } from '../../utilities/context/AlertContextProvider';
import { Paper, Typography } from '@mui/material';

const useStyles = makeStyles({
  container: {
    padding: '40px',
  },
  title: {
    marginBottom: '10px',
  },
});

const OrderManual = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { handleSetMessage } = useAlertContext();
  const classes = useStyles();
  const { setTitle } = useTitle();

  const getUrlParam = (param: string) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param) || '';
  };

  const tnId = getUrlParam('id');
  const store = getUrlParam('store');

  if (tnId) {
    setTitle(`Orden ${tnId}`);
  }

  const handleAccept = () => {
    setIsLoading(true);
    setButtonDisabled(true);
    saveManualOrder({
      id: tnId,
      store_id: store,
    })
      .then((response) => {
        console.log('🚀 ~ file: OrderManual.tsx:46 ~ .then ~ response', response);
        if (response.status === 201) {
          const trackingId = response?.results?.tracking_id;
          handleSetMessage({
            message: `Orden ${trackingId} Generada`,
            color: 'success',
            backHistory: true,
          });
        } else {
          let message = 'Ha ocurrido un error al generar la Orden';
          if (response?.message?.errors) {
            const keys = Object.keys(response?.message?.errors);
            for (let i = 0; i < keys.length; i++) {
              message += response?.message?.errors[keys[i]];
            }
          }
          handleSetMessage({
            message: message,
            color: 'error',
          });
          setButtonDisabled(false);
        }
      })
      .catch((error) => {
        handleSetMessage({
          message: 'Ha ocurrido un error al generar la Orden',
          color: 'error',
        });
        setButtonDisabled(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Loading loading={isLoading}>
      <Paper elevation={3} className={classes.container}>
        <div className={classes.title}>
          <Typography component="h1" variant="h5" className={classes.title}>
            Generar envío en iFlow para la orden {tnId}
          </Typography>
        </div>
        <ModalDialog
          onAccept={handleAccept}
          buttonLabel="Generar Order"
          dialogTitle="¿Esta seguro que desea generar el envío en iFlow?"
          buttonDisabled={buttonDisabled}
        />
      </Paper>
    </Loading>
  );
};

export default OrderManual;
