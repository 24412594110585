import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getStatusById } from '../../../services/iFlowServices';

const transformData = (data: any) => {
  return {
    ...data,
  };
};

export const useFetchStatusById = (): any => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchOrders = async (id: string) => {
    setLoading(true);
    const response = await getStatusById(id);
    const data = transformData(response);
    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    if (id) {
      fetchOrders(id);
    } else {
      setLoading(false);
    }
  }, [id]);

  return { data, loading };
};
