import Listado from '../../components/Listado';
import { useTitle } from '../../components/Main';
import { deleteStatus } from '../../services/iFlowServices';
import { useFetchStatus } from '../../utilities/hooks/useFetchStatus';
import { useAlertContext } from '../../utilities/context/AlertContextProvider';

const StatusPage = () => {
  const { setTitle } = useTitle();
  setTitle('Estados');

  const { data, pagination, loading, handleChangeFilters, handleChangePagination } = useFetchStatus();
  const { handleSetMessage } = useAlertContext();

  const columnsAdmin: any = {
    columns: [
      { name: 'iflowStatus', label: 'Estado iFLOW', minWidth: 90 },
      { name: 'tiendaNubeStatus', label: 'Estado TN', minWidth: 90 },
      { name: 'finalState', label: 'Estado Final?', minWidth: 90 },
    ],
    actions: {
      edit: { name: 'edit', label: 'Editar', minWidth: 10, link: '/status/' },
      add: { name: 'add', label: 'Nuevo', minWidth: 10, link: '/status/new' },
      delete: {
        name: 'delete',
        label: 'Eliminar',
        minWidth: 10,
        click: async (id: any) => {
          try {
            await deleteStatus(id);
            handleSetMessage({
              message: `Estado eliminado`,
              color: 'success',
            });
            handleChangePagination();
          } catch (err) {
            handleSetMessage({
              message: 'Ha ocurrido un error al eliminar el estado',
              color: 'error',
            });
          }
        },
      },
    },
  };

  return (
    <div>
      {
        <Listado
          loading={loading}
          listadoColumnas={columnsAdmin}
          rows={data || []}
          pagination={pagination}
          handleChangeFilters={handleChangeFilters}
          handleChangePagination={handleChangePagination}
        />
      }
    </div>
  );
};

export default StatusPage;
