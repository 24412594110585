import Loading from '../../components/Loading';
import { useTitle } from '../../components/Main';
import { useFetchDashboard } from '../../utilities/hooks/useFetchDashboard';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { Link } from 'react-router-dom';
import { Grid } from '@mui/material';

const useStyles = makeStyles({
  card: {
    marginBottom: '10px',
    // marginRight: '10px',
    margin: '0 auto',
    width: '100%',
  },
  grid: {
    padding: '20px',
  },
});

const DashboardPage = () => {
  const classes = useStyles();
  const { setTitle } = useTitle();
  setTitle('Dashboard');

  const { response, loading } = useFetchDashboard();

  return (
    <Loading loading={loading}>
      {response && (
        <>
          <Grid container spacing={3} className={classes.grid} xs={12}>
            <Grid container spacing={3} className={classes.grid} xs={3}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Ordenes del mes
                  </Typography>
                  <Typography variant="h5" component="div">
                    {response.order['month'] || 0}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to="/orders">
                    Ir a Ordenes
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid container spacing={3} className={classes.grid} xs={3}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Ordenes creadas
                  </Typography>
                  <Typography variant="h5" component="div">
                    {response.order['created'] || 0}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to="/orders">
                    Ir a Ordenes
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid container spacing={3} className={classes.grid} xs={3}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Ordenes procesadas
                  </Typography>
                  <Typography variant="h5" component="div">
                    {response.order['processed'] || 0}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to="/orders">
                    Ir a Ordenes
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid container spacing={3} className={classes.grid} xs={3}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Ordenes no procesadas
                  </Typography>
                  <Typography variant="h5" component="div">
                    {response.order['notProcessed'] || 0}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" component={Link} to="/orders">
                    Ir a Ordenes
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={3} className={classes.grid} xs={3}>
            <Card className={classes.card}>
              <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  Total Vendedores
                </Typography>
                <Typography variant="h5" component="div">
                  {response.seller['total'] || 0}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" component={Link} to="/sellers">
                  Ir a Vendedores
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </>
      )}
    </Loading>
  );
};

export default DashboardPage;
