import React from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import Main from '../components/Main';
import OrdersPage from '../pages/OrdersPage';
import DashboardPage from '../pages/DashboardPage';
import UsersPage from '../pages/UsersPage';
import SellersPage from '../pages/SellersPage';
import StatusPage from '../pages/StatusPage';
import StatusDetailPage from '../pages/StatusPage/StatusDetailPage';
import { useAuthContext } from '../utilities/context/AuthContextProvider';
import OrderDetailPage from '../pages/OrdersPage/OrderDetailPage';
import OrderEditPage from '../pages/OrdersPage/OrderEditPage';
import SellerDetailPage from '../pages/SellersPage/SellerDetailPage';
import ConfigPage from '../pages/ConfigPage';

const PrivateRoutes = () => {
  const { authContext } = useAuthContext();
  return useRoutes([
    {
      path: '/',
      element: authContext.token ? <Main /> : <Navigate replace to="/login" />,
      children: [
        {
          path: '/',
          element: <DashboardPage />,
        },
        {
          path: '/orders',
          element: <OrdersPage />,
        },
        {
          path: '/order/edit/:id',
          element: <OrderEditPage />,
        },
        {
          path: '/order/:id',
          element: <OrderDetailPage />,
        },
        {
          path: '/users',
          element: <UsersPage />,
        },
        {
          path: '/sellers',
          element: <SellersPage />,
        },
        {
          path: '/sellers/:id',
          element: <SellerDetailPage />,
        },
        {
          path: '/status',
          element: <StatusPage />,
        },
        {
          path: '/status/new',
          element: <StatusDetailPage />,
        },
        {
          path: '/status/:id',
          element: <StatusDetailPage />,
        },
        {
          path: '/config',
          element: <ConfigPage />,
        },
      ],
    },
  ]);
};

export default PrivateRoutes;
