/* eslint-disable quotes */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import { Link } from 'react-router-dom';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DeleteSharpIcon from '@mui/icons-material/DeleteSharp';
import ConfirmDialog from '../../components/ConfirmDialog';
import PauseIcon from '@mui/icons-material/Pause';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import VisibilityIcon from '@mui/icons-material/Visibility';
interface Props {
  actions: { [k: string]: any };
  row: any;
}

export default function ListadoBodyActions(props: Props) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openDialogPause, setOpenDialogPause] = React.useState(false);
  const [openDialogPlay, setOpenDialogPlay] = React.useState(false);
  const { actions, row } = props;
  const styles = {
    edit: {
      marginRight: '10px',
    },
  };

  const handleDialogAgree = (id: string, action: string, cb: any) => {
    switch (action) {
      case 'delete':
        setOpenDialog(false);
        break;
      case 'pause':
        setOpenDialogPause(false);
        break;
      case 'play':
        setOpenDialogPlay(false);
        break;
    }
    cb(id, row['paused']);
  };
  const handleClose = (action: string) => {
    switch (action) {
      case 'delete':
        setOpenDialog(false);
        break;
      case 'pause':
        setOpenDialogPause(false);
        break;
      case 'play':
        setOpenDialogPlay(false);
        break;
    }
  };
  const handleOpenDialog = (action: string) => {
    console.log('handleOpenDialog', action);
    switch (action) {
      case 'delete':
        setOpenDialog(true);
        break;
      case 'pause':
        setOpenDialogPause(true);
        break;
      case 'play':
        setOpenDialogPlay(true);
        break;
    }
  };
  return (
    <>
      <TableCell key={'actions'}>
        {actions.edit && (
          <Link to={actions.edit.link + row['_id'] || ''}>
            <EditSharpIcon color="primary" style={styles.edit} />
          </Link>
        )}
        {actions.view && (
          <Link to={actions.view.link + row['_id'] || ''}>
            <VisibilityIcon color="primary" style={styles.edit} />
          </Link>
        )}
        {actions.delete && (
          <>
            <DeleteSharpIcon color="error" onClick={() => handleOpenDialog('delete')} style={{ cursor: 'pointer' }} />
            <ConfirmDialog
              open={openDialog}
              title="Confirmar Acción"
              message="¿Esta seguro que desea eliminar el elemento seleccionado?"
              handleAgree={() => handleDialogAgree(row['_id'], 'delete', actions.delete.click)}
              handleClose={() => handleClose('delete')}
            />
          </>
        )}
        {actions.paused && !row['paused'] && (
          <>
            <PauseIcon color="error" onClick={() => handleOpenDialog('pause')} style={{ cursor: 'pointer' }} />
            <ConfirmDialog
              open={openDialogPause}
              title="Confirmar Acción"
              message="¿Esta seguro que desea pausar el elemento seleccionado?"
              handleAgree={() => handleDialogAgree(row['_id'], 'pause', actions.paused.click)}
              handleClose={() => handleClose('pause')}
            />
          </>
        )}
        {actions.paused && row['paused'] && (
          <>
            <PlayCircleFilledIcon color="error" onClick={() => handleOpenDialog('play')} style={{ cursor: 'pointer' }} />
            <ConfirmDialog
              open={openDialogPlay}
              title="Confirmar Acción"
              message="¿Esta seguro que desea habilitar el elemento seleccionado?"
              handleAgree={() => handleDialogAgree(row['_id'], 'play', actions.paused.click)}
              handleClose={() => handleClose('play')}
            />
          </>
        )}
      </TableCell>
    </>
  );
}
