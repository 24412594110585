export const tableDateFormat = (dateString: string) => {
  const appendZero = (numb: number) => {
    return (numb > 9 ? '' : '0') + numb;
  };
  const date = new Date(dateString);
  const day = appendZero(date.getDate());
  const month = appendZero(date.getMonth() + 1);
  const year = appendZero(date.getFullYear());
  const hour = appendZero(date.getHours());
  const minutes = appendZero(date.getMinutes());
  return `${day}-${month}-${year} ${hour}:${minutes}`;
};
