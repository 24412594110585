import Listado from '../../components/Listado';
import { Filter } from '../../components/Listado/interfaces';
import { useTitle } from '../../components/Main';
import { useFetchSellers } from '../../utilities/hooks/useFetchSellers';

const columnsAdmin: any = {
  columns: [
    { name: 'iflowUser', label: 'Usuario iFLOW', minWidth: 90 },
    { name: 'iflowCuit', label: 'CUIT iFLOW', minWidth: 90 },
    { name: 'iflowBusinessName', label: 'Comercio', minWidth: 90 },
    { name: 'tnUserId', label: 'Usuario TN', minWidth: 90 },
    { name: 'iflowUrl', label: 'URL', minWidth: 90 },
    { name: 'tnAccessToken', label: 'Token', minWidth: 90 },
  ],
  actions: {
    view: { name: 'view', label: 'Ver', minWidth: 10, link: '/sellers/' },
  },
};

const filters: Filter[] = [
  { name: 'tnUserId', label: 'Usuario TN', type: 'text' },
  { name: 'iflowBusinessName', label: 'Comercio', type: 'text' },
  { name: 'iflowUser', label: 'Usuario', type: 'text' },
  { name: 'iflowCuit', label: 'CUIT', type: 'text' },
];

const SellersPage = () => {
  const { setTitle } = useTitle();
  setTitle('Usuarios');

  const { data, pagination, loading, handleChangeFilters, handleChangePagination } = useFetchSellers();

  return (
    <div>
      {
        <Listado
          loading={loading}
          filters={filters}
          listadoColumnas={columnsAdmin}
          rows={data || []}
          pagination={pagination}
          handleChangeFilters={handleChangeFilters}
          handleChangePagination={handleChangePagination}
        />
      }
    </div>
  );
};

export default SellersPage;
