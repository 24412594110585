import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getOrderById } from '../../../services/iFlowServices';
import { tableDateFormat } from '../../date';

const transformData = (order: any) => {
  return {
    ...order,
    orderCreateAt: tableDateFormat(order?.orderCreateAt),
    created: order?.processStatus?.created ? 'SI' : 'NO',
    paid: order?.processStatus?.paid ? 'SI' : 'NO',
    packed: order?.processStatus?.packed ? 'SI' : 'NO',
    fulfill: order?.processStatus?.fulfill ? 'SI' : 'NO',
    cancelled: order?.processStatus?.cancelled ? 'SI' : 'NO',
  };
};

export const useFetchOrderById = (): any => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchOrders = async (id: string) => {
    setLoading(true);
    const response = await getOrderById(id);
    const data = transformData(response);
    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    if (id) fetchOrders(id);
  }, [id]);

  return { data, loading };
};
