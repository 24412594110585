/* eslint-disable quotes */
import React from 'react';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { ListadoColumnas, RequestOptionsListado, Filter } from './interfaces';
import ListadoHeader from './ListadoHeader';
import ListadoBody from './ListadoBody';
import ListadoFilters from './ListadoFilters';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { createTheme } from '@mui/material';

const theme = createTheme();

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    /*maxHeight: 440,*/

    [theme.breakpoints.down('xs')]: {
      maxWidth: 400,
    },
  },
  addButton: {
    marginLeft: '10px',
    marginBottom: '10px',
  },
});

interface Props {
  listadoColumnas: ListadoColumnas;
  rows: any[];
  loading: boolean;
  pagination?: any;
  filters?: Filter[];
  handleChangePagination?: (options: RequestOptionsListado) => void;
  handleChangeFilters?: (options: RequestOptionsListado) => void;
}

export default function Listado(props: Props) {
  const classes = useStyles();
  const { listadoColumnas, loading = true, rows, pagination, handleChangePagination, handleChangeFilters, filters } = props;

  const [page, setPage] = React.useState(pagination?.page ? pagination.page - 1 : 0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtersS, setFiltersS] = React.useState({});

  let pageCount = 1;
  if (pagination) {
    pageCount = pagination ? pagination.totalDocs * pagination.totalPages : -1;
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    const options: RequestOptionsListado = {
      page: newPage + 1,
      rowsPerPage: rowsPerPage,
      ...filtersS,
    };
    if (handleChangePagination) handleChangePagination(options);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const options: RequestOptionsListado = {
      page: 1,
      rowsPerPage: +event.target.value,
      ...filtersS,
    };
    if (handleChangePagination) handleChangePagination(options);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onHandleChangeFilter = (key: string, value: string | number) => {
    const options: RequestOptionsListado = {
      page: 1,
      rowsPerPage: rowsPerPage || 10,
    };
    const filter = {
      ...filtersS,
      [key]: value,
    };
    setPage(0);
    setFiltersS(filter);
    if (handleChangeFilters) handleChangeFilters({ ...options, ...filter });
  };

  return (
    <Paper className={classes.root}>
      {filters && <ListadoFilters filters={filters} onHandleChange={onHandleChangeFilter} />}
      {listadoColumnas.actions && listadoColumnas.actions.add && (
        <Button component={Link} to={listadoColumnas.actions.add.link} variant="contained" color="primary" className={classes.addButton}>
          Nuevo
        </Button>
      )}
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <ListadoHeader listadoColumnas={listadoColumnas} />
          <ListadoBody listadoColumnas={listadoColumnas} rows={rows} loading={loading} />
        </Table>
      </TableContainer>
      {pagination && handleChangePagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={pageCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Resultados por página"
          labelDisplayedRows={({ from, to, count }) => from.toString() + (count !== -1 ? ' de ' + count.toString() : ' más de ' + to.toString())}
        />
      )}
    </Paper>
  );
}
