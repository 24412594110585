import React from 'react';
// import { useHistory } from "react-router-dom";
import { UserAlert } from '../../interfaces/interfaces';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const useStyles = makeStyles({
  snackbar: {
    width: '100%',
  },
  alert: {
    width: '50%',
    margin: '0 auto',
  },
});

interface Props {
  onCancel?: () => void;
  onAccept: () => void;
  buttonLabel: string;
  dialogTitle: string;
  dialogText?: string;
  buttonDisabled?: boolean;
}
function ModalDialog({ onCancel, onAccept, buttonLabel, dialogTitle, dialogText, buttonDisabled = false }: Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAccept = () => {
    setOpen(false);
    onAccept();
  };

  const handleClose = () => {
    setOpen(false);
    onCancel && onCancel();
  };
  return (
    <>
      <Button variant="contained" onClick={handleClickOpen} disabled={buttonDisabled}>
        {buttonLabel}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
        {dialogText && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{dialogText}</DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAccept} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ModalDialog;
