import * as React from 'react';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';

enum variant {
  outlined = 'outlined',
  standard = 'standard',
  filled = 'filled',
}
interface Props {
  name: string;
  label: string;
  control: any;
  defaultValue?: string;
  variant?: variant;
  type?: string;
  errors: any;
}

export default function Input(props: Props) {
  const { name, label, control, defaultValue = '', variant = 'outlined', type = 'text' } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{ required: true }}
      render={({ field: { ref, ...field }, fieldState: { invalid, error } }) => (
        <TextField
          {...field}
          margin="normal"
          inputRef={ref}
          id={name}
          autoComplete={name}
          variant={variant}
          fullWidth
          error={invalid}
          label={label}
          type={type}
        />
      )}
    />
  );
}
