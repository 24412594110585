import { useEffect, useState } from 'react';
import { getOrderByTnIdStoreId } from '../../../services/iFlowServices';
import { tableDateFormat } from '../../date';

const transformData = (order: any) => {
  return {
    ...order.order,
    orderCreateAt: tableDateFormat(order?.order?.orderCreateAt),
    rate: order?.rate,
  };
};

export const useFetchOrderByTnIdStoreId = (tnId: string, storeId: string): any => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);

  const fetchOrders = async (tnId: string, storeId: string) => {
    try {
      setLoading(true);
      const response = await getOrderByTnIdStoreId(tnId, storeId);
      const data = transformData(response);
      setData(data);
      setLoading(false);
      setError(false);
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (tnId && storeId) fetchOrders(tnId, storeId);
  }, [tnId, storeId]);

  return { data, loading, error };
};
