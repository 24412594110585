import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSellerById } from '../../../services/iFlowServices';

const transformData = (data: any) => {
  return {
    ...data,
  };
};

export const useFetchSellerById = (): any => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchOrders = async (id: string) => {
    setLoading(true);
    const response = await getSellerById(id);
    console.log('🚀 ~ file: index.tsx:13 ~ fetchOrders ~ orders', response);
    const data = transformData(response);
    setData(data);
    setLoading(false);
  };
  useEffect(() => {
    if (id) fetchOrders(id);
  }, [id]);

  return { data, loading };
};
