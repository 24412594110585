import React, { useContext, useState } from 'react';
import { AuthContextState, UserContext } from '../../interfaces/interfaces';

export const AuthContext = React.createContext<AuthContextState>({
  authContext: {},
  loginUser: (user: any) => {},
  logoutUser: () => {},
});

export const useAuthContextState = (initialValue?: AuthContextState) => {
  let initialContext: UserContext = {};
  if (localStorage.getItem('token') && localStorage.getItem('username')) {
    initialContext = {
      token: localStorage.getItem('token') || '',
      username: localStorage.getItem('username') || '',
    };
  }

  const [context, setContext] = useState<UserContext>(initialContext);

  return { authContext: context, setAuthContext: setContext };
};

export const useAuthContext = () => useContext(AuthContext);

export interface Props {
  children?: React.ReactNode;
  initialValue?: AuthContextState;
}

export const AuthContextProvider = ({ children, initialValue }: Props) => {
  const { authContext, setAuthContext } = useAuthContextState(initialValue);
  const loginUser = ({ token, username }: any) => {
    setAuthContext({
      token,
      username,
    });
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
  };
  const logoutUser = () => {
    setAuthContext({});
    localStorage.removeItem('token');
    localStorage.removeItem('username');
  };
  return <AuthContext.Provider value={{ authContext, loginUser, logoutUser }}>{children}</AuthContext.Provider>;
};
