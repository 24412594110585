import { useEffect, useState } from 'react';
import { RequestOptionsListado } from '../../../components/Listado/interfaces';
import { getOrders } from '../../../services/iFlowServices';
import { tableDateFormat } from '../../date';

const transformData = (orders: any) => {
  return orders.map((order: any) => {
    return {
      ...order,
      orderCreateAt: tableDateFormat(order?.orderCreateAt),
      created: order?.processStatus?.created ? 'SI' : 'NO',
      paid: order?.processStatus?.paid ? 'SI' : 'NO',
      packed: order?.processStatus?.packed ? 'SI' : 'NO',
      fulfill: order?.processStatus?.fulfill ? 'SI' : 'NO',
      cancelled: order?.processStatus?.cancelled ? 'SI' : 'NO',
    };
  });
};

export const useFetchOrders = () => {
  const [orders, setOrders] = useState();
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchOrders = async (options?: RequestOptionsListado) => {
    setLoading(true);
    const response = await getOrders(options);
    console.log('🚀 ~ file: index.tsx:13 ~ fetchOrders ~ orders', response);
    const orders = transformData(response.docs);
    setOrders(orders);
    setPagination(response);
    setLoading(false);
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  const handleChangeFilters = async (options?: RequestOptionsListado) => {
    fetchOrders(options);
  };

  const handleChangePagination = async (options?: RequestOptionsListado) => {
    fetchOrders(options);
  };

  return { orders, pagination, loading, handleChangeFilters, handleChangePagination };
};
