import React, { useContext, useState } from 'react';
import SnackbarCustom from '../../components/SnakbarNotification';
import { AlertContextState, UserAlert } from '../../interfaces/interfaces';
import { useNavigate } from 'react-router-dom';

export const AlertContext = React.createContext({
  handleSetMessage: (param: UserAlert) => {},
});

export const useAlertContextState = (initialValue?: AlertContextState) => {
  const initialContext: UserAlert = {};

  const [message, setMessage] = useState<UserAlert>(initialContext);

  return { message: message, setMessage: setMessage };
};

export const useAlertContext = () => useContext(AlertContext);

export interface Props {
  children?: React.ReactNode;
  initialValue?: AlertContextState;
}

export const AlertContextProvider = ({ children, initialValue }: Props) => {
  const { message, setMessage } = useAlertContextState(initialValue);
  const navigate = useNavigate();

  const handleClose = () => {
    // userAlertContext.setAlertContext(false)
  };
  const handleSetMessage = ({ message, color, duration = 5000, redirect, backHistory }: UserAlert) => {
    console.log('handleSetMessage();');
    setMessage({
      open: true,
      message,
      color,
    });
    setTimeout(() => {
      setMessage({
        open: false,
        message: '',
        color,
      });
      if (redirect) {
        navigate(redirect);
      }
      if (backHistory === true) {
        window.history.back();
      }
    }, duration);
  };
  return (
    <AlertContext.Provider value={{ handleSetMessage }}>
      <SnackbarCustom closeUserAlert={handleClose} userAlert={message} />
      {children}
    </AlertContext.Provider>
  );
};
