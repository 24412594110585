import React from 'react';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';

const theme = createTheme();
const useStyles = makeStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
});
interface Props {
  select: {
    name: string;
    label: string;
    multiple?: boolean;
    defaultOption?: boolean;
    fullWidth?: boolean;
    options?: {
      value: string;
      label: string;
      default?: boolean;
    }[];
    callback?: (value: string | number) => void;
    defaultValue?: string;
  };
  onHandleChange: (key: string, value: any) => void;
}

export default function ComboSelect(props: Props) {
  const classes = useStyles();
  const { select, onHandleChange } = props;

  const multiple = select.multiple ? select.multiple : false;

  const defaultOption = typeof select.defaultOption !== 'undefined' ? select.defaultOption : true;
  const defaultValue = typeof select.defaultValue !== 'undefined' ? select.defaultValue : '';

  const defaultMultipleOption =
    multiple && select.options && select.options.length > 0 ? select.options.filter((o) => o.default).map((o) => JSON.stringify(o)) : [];

  const [valueSelect, setValueSelect] = React.useState<string[] | string>(multiple ? defaultMultipleOption : '');

  const handleChange = (e: any) => {
    if (select.callback) {
      select.callback(typeof e.target.value == 'string' || typeof e.target.value == 'number' ? e.target.value : '');
    } else {
      if (!multiple) {
        onHandleChange(select.name, typeof e.target.value == 'string' || typeof e.target.value == 'number' ? e.target.value : '');
      } else {
        const setValueData = (e.target.value as string[]).map((value: string) => {
          return JSON.parse(value).value;
        });
        setValueSelect(e.target.value);
        onHandleChange(select.name, setValueData);
      }
    }
  };
  const selectSimple = () => {
    if (!select.options) return null;
    return (
      <Select
        labelId={select.name}
        defaultValue={defaultValue}
        id={select.name}
        onChange={handleChange}
        style={{ width: '200px', marginLeft: '10px' }}
        fullWidth={true}
      >
        {defaultOption && (
          <MenuItem value="">
            <em>Seleccione una opción</em>
          </MenuItem>
        )}
        {select.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  };
  const selectMultiple = () => {
    if (!select.options) return null;
    return (
      <Select
        labelId={select.name}
        defaultValue={''}
        id={select.name}
        onChange={handleChange}
        multiple={multiple}
        value={valueSelect}
        fullWidth={typeof select.fullWidth !== 'undefined' ? select.fullWidth : false}
        renderValue={(selected: any) => {
          return (
            <div className={classes.chips}>
              {multiple &&
                selected.map((select: any) => {
                  const option = JSON.parse(select);
                  return <Chip key={option.value} label={option.label} className={classes.chip} />;
                })}
              {!multiple && <label>{selected}</label>}
            </div>
          );
        }}
      >
        {defaultOption && (
          <MenuItem value="">
            <em>Seleccione una opción</em>
          </MenuItem>
        )}
        {select.options.map((option) => (
          <MenuItem key={option.value} value={JSON.stringify(option)}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <>
      {multiple && selectMultiple()}
      {!multiple && selectSimple()}
    </>
  );
}
