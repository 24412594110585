import React, { useEffect, useState } from 'react';
import styles from './AddSeller.module.css';
import { Alert, Snackbar, TextField } from '@mui/material';
import { textFieldStyle, buttonStyle } from './AddSeller.utils';
import { activateSeller, getTnToken } from './AddSeller.service';
import { LoadingButton } from '@mui/lab';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export default function AddSeller() {
  const [iflowUser, setIflowUser] = useState('');
  const [iflowPassword, setIflowPassword] = useState('');
  const [iflowBusinessName, setIflowBusinessName] = useState('');
  const [iflowCuit, setIflowCuit] = useState('');
  const [iflowUrl, setIflowUrl] = useState('');
  const [tnAccessToken, setTnAccessToken] = useState('');
  const [tnTokenType, setTnTokenType] = useState('');
  const [tnScope, setTnScope] = useState('');
  const [tnUserId, setTnUserId] = useState('');
  const [tokenSuccess, setTokenSuccess] = useState(false);
  const [activationError, setActivationError] = useState('');
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [inputBlured, setInputBlured] = useState({ businessName: false, cuit: false, url: false, user: false, password: false });
  const [isActivating, setIsActivating] = useState(false);
  const [activationSuccess, setActivationSuccess] = useState(false);
  const [isSetAccessToken, setIsSetAccessToken] = useState(false);

  useEffect(() => {
    getTiendaNubeToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUrlParam = (param: string) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param) || '';
  };

  const getTiendaNubeToken = () => {
    getTnToken(getUrlParam('code')).then((response) => {
      if (!isSetAccessToken) {
        setTnAccessToken(response.data.access_token);
        setTnTokenType(response.data.token_type);
        setTnScope(response.data.scope);
        setTnUserId(response.data.user_id);
        setTokenSuccess(!!response.data.access_token);
        setIsSetAccessToken(true);
      }
    });
  };

  const handleChangeUser = (event: any) => setIflowUser(event.target.value);
  const handleChangePassword = (event: any) => setIflowPassword(event.target.value);
  const handleChangeBusinessName = (event: any) => setIflowBusinessName(event.target.value);
  const handleChangeCuit = (event: any) => setIflowCuit(event.target.value);
  const handleChangeUrl = (event: any) => setIflowUrl(event.target.value);

  const handleOnClickActive = () => {
    setOpenErrorModal(false);
    setIsActivating(true);
    const data = {
      iflowUser,
      iflowPassword,
      iflowBusinessName,
      iflowCuit,
      iflowUrl,
      tnAccessToken,
      tnTokenType,
      tnScope,
      tnUserId,
    };
    activateSeller(data)
      .then((response) => {
        if (response.data === true) {
          setActivationSuccess(true);
          setTimeout(() => {
            window.history.go(-2);
          }, 5000);
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.data && error.response.data.message) {
          setActivationError(error.response.data.message);
          setOpenErrorModal(true);
        }
      })
      .finally(() => {
        setIsActivating(false);
      });
  };

  const isCuitValid = (cuit: string): boolean => {
    const regexCuit = /^(20|23|27|30|33)([0-9]{9}|-[0-9]{8}-[0-9]{1})$/g;
    return regexCuit.test(cuit);
  };
  const isURLValid = (url: string): boolean => {
    const regexUrl = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;
    return regexUrl.test(url);
  };

  const businessNameValid = iflowBusinessName && iflowBusinessName.length > 0;
  const cuitValid = iflowCuit && isCuitValid(iflowCuit);
  const urlValid = iflowUrl && isURLValid(iflowUrl);
  const userValid = iflowUser && iflowUser.length > 0;
  const passwordValid = iflowPassword && iflowPassword.length > 0;
  const enableButton = userValid && passwordValid && businessNameValid && cuitValid && urlValid && tnAccessToken;

  const handleClose = () => setOpenErrorModal(false);

  const handleBlurInput = (input: string) => {
    setInputBlured({
      ...inputBlured,
      [input]: true,
    });
  };

  return (
    <div className={styles.background}>
      <div className={styles.iflowLogo}>
        <img src={process.env.PUBLIC_URL + '/iflow_logo.png'} alt="" />
      </div>
      {activationSuccess && (
        <div className={styles.activationSuccessContainer}>
          <div className={styles.iconContainer}>
            <CheckCircleOutlineIcon fontSize="large" sx={{ color: '#93c610' }} />
          </div>
          La aplication ha sido activada correctamente. Sera redirigido a tiendanube
          <a onClick={() => window.history.back()}>Haz click aquí para retornar a tiendanube</a>
        </div>
      )}
      {!activationSuccess && (
        <>
          {!tokenSuccess && (
            <div className={styles.tokenExpiredContainer}>El codigo de autorización expiró, no existe o es inválido para el cliente</div>
          )}
          {tokenSuccess && (
            <div className={styles.container}>
              <div className={styles.formContainer}>
                <div className={styles.activationText}>Formulario de Activación</div>
                <div className={styles.iFlowLogoForm}>
                  <img src={process.env.PUBLIC_URL + '/iflow_logo.png'} alt="" className={styles.iFlowLogoFormImg} />
                </div>
                <div className={styles.inputContainer}>
                  <TextField
                    label="Razón social"
                    variant="outlined"
                    sx={textFieldStyle}
                    className={styles.inputText}
                    onChange={handleChangeBusinessName}
                    onBlur={() => handleBlurInput('businessName')}
                    error={inputBlured.businessName && !businessNameValid}
                    helperText={inputBlured.businessName && !businessNameValid ? 'La Razón social es requerida.' : ''}
                    disabled={isActivating}
                    autoComplete="off"
                  />
                </div>

                <div className={styles.inputContainer}>
                  <TextField
                    label="Nro de CUIT"
                    variant="outlined"
                    sx={textFieldStyle}
                    className={styles.inputText}
                    onChange={handleChangeCuit}
                    onBlur={() => handleBlurInput('cuit')}
                    error={inputBlured.cuit && !cuitValid}
                    helperText={inputBlured.cuit && !cuitValid ? 'El número de CUIT no es válido.' : ''}
                    disabled={isActivating}
                    autoComplete="off"
                  />
                </div>
                <div className={styles.inputContainer}>
                  <TextField
                    label="URL de la tienda"
                    variant="outlined"
                    sx={textFieldStyle}
                    className={styles.inputText}
                    onChange={handleChangeUrl}
                    onBlur={() => handleBlurInput('url')}
                    error={inputBlured.url && !urlValid}
                    helperText={inputBlured.url && !urlValid ? 'La URL de la tienda no es válida' : ''}
                    disabled={isActivating}
                    autoComplete="off"
                  />
                </div>

                <div className={styles.inputContainer}>
                  <TextField
                    label="Usuario"
                    variant="outlined"
                    sx={textFieldStyle}
                    className={styles.inputText}
                    onChange={handleChangeUser}
                    onBlur={() => handleBlurInput('user')}
                    error={inputBlured.user && !userValid}
                    helperText={inputBlured.user && !userValid ? 'El Usuario es requerido.' : ''}
                    disabled={isActivating}
                    autoComplete="off"
                  />
                </div>
                <div className={styles.inputContainer}>
                  <TextField
                    label="Contraseña"
                    variant="outlined"
                    sx={textFieldStyle}
                    className={styles.inputText}
                    onChange={handleChangePassword}
                    type="password"
                    onBlur={() => handleBlurInput('password')}
                    error={inputBlured.password && !passwordValid}
                    helperText={inputBlured.password && !passwordValid ? 'La contraseña es requerida.' : ''}
                    disabled={isActivating}
                    autoComplete="off"
                  />
                </div>
                <div className={styles.buttonContainer}>
                  <LoadingButton
                    loading={isActivating}
                    variant="contained"
                    sx={buttonStyle}
                    className={styles.linkButton}
                    disabled={!enableButton || isActivating}
                    onClick={handleOnClickActive}
                  >
                    {isActivating ? 'Activando' : 'Activar Aplicación'}
                  </LoadingButton>
                </div>
                <div className={styles.forgotDataContainer}>
                  <a className={styles.forgotData} href="https://webticket.iflow21.com/index.php">
                    Olvidó sus datos ?
                  </a>
                </div>
              </div>

              <div className={styles.signUpContainer}>
                <div className={styles.signUpWelcome}>Bienvenido a IFLOW S.A.</div>
                <div className={styles.signUpText}>
                  Si aun no tiene una cuenta activa contactese con el departamento comercial haciendo click{' '}
                  <a className={styles.signUpLink} href="https://www.iflow21.com/contacto/">
                    aquí
                  </a>
                </div>
              </div>
              <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openErrorModal} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  {activationError}
                </Alert>
              </Snackbar>
            </div>
          )}
        </>
      )}
    </div>
  );
}
