import { useEffect, useState } from 'react';
import { RequestOptionsListado } from '../../../components/Listado/interfaces';
import { getSellers } from '../../../services/iFlowServices';

const transformData = (users: any) => {
  return users.map((user: any) => {
    return {
      ...user,
    };
  });
};

export const useFetchSellers = () => {
  const [data, setData] = useState();
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchOrders = async (options?: RequestOptionsListado) => {
    setLoading(true);
    const response = await getSellers(options);
    const data = transformData(response.docs);
    setData(data);
    setPagination(response);
    setLoading(false);
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  const handleChangeFilters = async (options?: RequestOptionsListado) => {
    fetchOrders(options);
  };

  const handleChangePagination = async (options?: RequestOptionsListado) => {
    fetchOrders(options);
  };

  return { data, pagination, loading, handleChangeFilters, handleChangePagination };
};
