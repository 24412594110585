import Loading from '../../components/Loading';
import { useTitle } from '../../components/Main';
import { useFetchSellerById } from '../../utilities/hooks/useFetchSellerById';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Divider, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TypographyRow from '../../components/DetailPage/TypographyRow';
import Listado from '../../components/Listado';

const useStyles = makeStyles({
  card: {
    marginBottom: '10px',
  },
  webhooksContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

const SellerDetailPage = () => {
  const classes = useStyles();
  const { setTitle } = useTitle();

  setTitle(`Vendedor`);

  const { data, loading } = useFetchSellerById();

  const {
    iflowBusinessName,
    iflowCuit,
    iflowShippingOption,
    iflowUser,
    tnUserId,
    webhooks,
    tnAccessToken,
    shippingCarriers,
    shippingCarriersOptions,
  } = data;
  if (data) {
    setTitle(`Vendedor: ${iflowBusinessName}`);
  }

  const columns: any = {
    columns: [
      { name: 'idWebhook', label: 'Id', minWidth: 90 },
      { name: 'url', label: 'Url', minWidth: 90 },
      { name: 'type', label: 'Tipo', minWidth: 90 },
      { name: 'status', label: 'Estado', minWidth: 90 },
    ],
  };

  const shippingCarriersColumns: any = {
    columns: [
      { name: 'id', label: 'Id', minWidth: 90 },
      { name: 'callbackUrl', label: 'Callback Url', minWidth: 90 },
      { name: 'name', label: 'Nombre', minWidth: 90 },
      { name: 'types', label: 'Tipo', minWidth: 90 },
      { name: 'active', label: 'Activo', minWidth: 90 },
    ],
  };

  const shippingCarriersOptionsColumns: any = {
    columns: [
      { name: 'id', label: 'Id', minWidth: 90 },
      { name: 'name', label: 'Nombre', minWidth: 90 },
      { name: 'freeShipping', label: 'Permite envío gratis', minWidth: 90 },
      { name: 'code', label: 'Código', minWidth: 90 },
      { name: 'additionalCost', label: 'Costo adicional', minWidth: 90 },
      { name: 'additionalDays', label: 'Días adicional', minWidth: 90 },
      { name: 'active', label: 'Activo', minWidth: 90 },
    ],
  };

  const transformShippingCarriers = (shippingCarriers: any) => {
    return shippingCarriers.map((sc: any) => {
      return {
        ...sc,
        active: sc.active === true ? 'Si' : 'No',
      };
    });
  };

  const transformShippingCarriersOptions = (shippingCarriersOptions: any) => {
    return shippingCarriersOptions.map((sco: any) => {
      return {
        ...sco,
        active: sco.active === true ? 'Si' : 'No',
        freeShipping: sco.allowFreeShipping === true ? 'Si' : 'No',
      };
    });
  };

  return (
    <Loading loading={loading}>
      <Card sx={{ minWidth: 275 }} className={classes.card}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Datos iFLOW
          </Typography>
          <Grid container spacing={2}>
            <TypographyRow label="Comercio" value={iflowBusinessName} />
            <TypographyRow label="Usuario" value={iflowUser} />
            <TypographyRow label="Tipo Envío" value={iflowShippingOption} />
            <TypographyRow label="CUIT" value={iflowCuit} />
            <TypographyRow label="Token" value={tnAccessToken} />
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275 }} className={classes.card}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Datos Tiendanube
          </Typography>
          <Grid container spacing={2}>
            <TypographyRow label="Id" value={tnUserId} />
          </Grid>
          <div className={classes.webhooksContainer}>
            <Divider />
          </div>
          <div>
            <TypographyRow label="Webhooks" />
            <Listado loading={false} listadoColumnas={columns} rows={webhooks || []} />
          </div>
          <div className={classes.webhooksContainer}>
            <Divider />
          </div>
          <div>
            <Typography variant="h6" component="div">
              Medios de envío
            </Typography>
            <Listado loading={false} listadoColumnas={shippingCarriersColumns} rows={transformShippingCarriers(shippingCarriers || [])} />
          </div>
          <div className={classes.webhooksContainer}>
            <Divider />
          </div>
          <div>
            <Typography variant="h6" component="div">
              Opciones de medios de envío
            </Typography>
            <Listado
              loading={false}
              listadoColumnas={shippingCarriersOptionsColumns}
              rows={transformShippingCarriersOptions(shippingCarriersOptions || [])}
            />
          </div>
        </CardContent>
      </Card>
    </Loading>
  );
};

export default SellerDetailPage;
