import axios from 'axios';
import { RequestOptionsListado } from '../components/Listado/interfaces';
import { PayloadManualOrder, PayloadReverseLogistic, PayloadStatus, Settings } from '../interfaces/interfaces';
import { apiBaseURL } from './settingsService';

const baseURL = `${apiBaseURL()}/`;
const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token') || '';
    // Do something before request is sent
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      window.location.href = '/login';
    }
    // Do something with response error
    return Promise.reject(error);
  },
);

interface loginI {
  username: string;
  password: string;
}

export const login = async ({ username, password }: loginI) => {
  try {
    const response = await instance.post('auth/login', {
      username,
      password,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getOrders = async (options?: RequestOptionsListado) => {
  try {
    const params = Object.entries(options || {})
      .map((e) => e.join('='))
      .join('&');
    const response = await instance.get(`order/?${params}`);
    return response.data;
  } catch (e) {
    console.log('🚀 ~ file: iFlowServices.ts:51 ~ getOrders ~ e', e);
    throw e;
  }
};
export const getDashboardOrders = async () => {
  try {
    const response = await instance.get(`order/dashboard`);
    return response.data;
  } catch (e) {
    console.log('🚀 ~ file: iFlowServices.ts:51 ~ getOrders ~ e', e);
    throw e;
  }
};
export const getDashboardSellers = async () => {
  try {
    const response = await instance.get(`seller/dashboard`);
    return response.data;
  } catch (e) {
    console.log('🚀 ~ file: iFlowServices.ts:51 ~ getOrders ~ e', e);
    throw e;
  }
};

export const getOrderById = async (id: string) => {
  try {
    const response = await instance.get(`order/${id}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const getOrderByTnIdStoreId = async (tnId: string, storeId: string) => {
  try {
    const response = await instance.get(`order/tiendanube/${tnId}/${storeId}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getUsers = async (options?: RequestOptionsListado) => {
  try {
    const params = Object.entries(options || {})
      .map((e) => e.join('='))
      .join('&');
    const response = await instance.get(`users/?${params}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getSellers = async (options?: RequestOptionsListado) => {
  try {
    const params = Object.entries(options || {})
      .map((e) => e.join('='))
      .join('&');
    const response = await instance.get(`seller/?${params}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getStatus = async (options?: RequestOptionsListado) => {
  try {
    const params = Object.entries(options || {})
      .map((e) => e.join('='))
      .join('&');
    const response = await instance.get(`status/?${params}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getStatusById = async (id: string) => {
  try {
    const response = await instance.get(`status/${id}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const updateStatus = async (id: string, payloadManualOrder: PayloadStatus) => {
  try {
    const response = await instance.put(`status/${id}`, payloadManualOrder);
    return response.data;
  } catch (e) {
    console.log('🚀 ~ file: iFlowServices.ts:153 ~ updateStatus ~ e', e);
    throw e;
  }
};
export const saveStatus = async (payloadManualOrder: PayloadStatus) => {
  try {
    const response = await instance.post(`status`, payloadManualOrder);
    return response.data;
  } catch (e) {
    console.log('🚀 ~ file: iFlowServices.ts:153 ~ updateStatus ~ e', e);
    throw e;
  }
};
export const deleteStatus = async (id: string) => {
  try {
    const response = await instance.delete(`status/${id}`);
    return response.data;
  } catch (e) {
    console.log('🚀 ~ file: iFlowServices.ts:153 ~ updateStatus ~ e', e);
    throw e;
  }
};

export const getSellerById = async (id: string) => {
  try {
    const response = await instance.get(`seller/${id}`);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getSettings = async () => {
  try {
    const response = await instance.get('settings');
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const saveSettings = async (settings: Settings) => {
  try {
    const response = await instance.post('settings', settings);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const saveReverseLogistic = async (payloadReverseLogistic: PayloadReverseLogistic) => {
  try {
    const response = await instance.post('order/reverse', payloadReverseLogistic);
    return response.data;
  } catch (e) {
    throw e;
  }
};
export const saveManualOrder = async (payloadManualOrder: PayloadManualOrder) => {
  try {
    const response = await instance.post('order/manual/created', payloadManualOrder);
    return response.data;
  } catch (e) {
    console.log('🚀 ~ file: iFlowServices.ts:153 ~ saveManualOrder ~ e', e);
    throw e;
  }
};
export const sendShippingToIflow = async (payloadManualOrder: PayloadManualOrder) => {
  try {
    const response = await instance.post('order/reprocessed', payloadManualOrder);
    return response.data;
  } catch (e) {
    console.log('🚀 ~ file: iFlowServices.ts:153 ~ saveManualOrder ~ e', e);
    throw e;
  }
};

export const updateOrder = async (id: any, payloadOrder: any) => {
  try {
    const response = await instance.put(`order/${id}/shipping-address`, payloadOrder);
    return response.data;
  } catch (e) {
    console.log('🚀 ~ file: iFlowServices.ts:153 ~ updateORder ~ e', e);
    throw e;
  }
};
