import Listado from '../../components/Listado';
import { useTitle } from '../../components/Main';

import { useFetchUsers } from '../../utilities/hooks/useFetchUsers';

const columnsAdmin: any = {
  columns: [
    { name: 'username', label: 'Usuario', minWidth: 90 },
    { name: 'name', label: 'Nombre', minWidth: 90 },
    { name: 'lastname', label: 'Apellido', minWidth: 90 },
    { name: 'status', label: 'Estado', minWidth: 90 },
  ],
};

const UsersPage = () => {
  const { setTitle } = useTitle();
  setTitle('Usuarios');

  const { data, pagination, loading, handleChangeFilters, handleChangePagination } = useFetchUsers();

  return (
    <div>
      {
        <Listado
          loading={loading}
          listadoColumnas={columnsAdmin}
          rows={data || []}
          pagination={pagination}
          handleChangeFilters={handleChangeFilters}
          handleChangePagination={handleChangePagination}
        />
      }
    </div>
  );
};

export default UsersPage;
