import { useRoutes, Navigate } from 'react-router-dom';
import Main from '../components/Main';
import ActivatePage from '../pages/ActivatePage/ActivatePage';
import ReverseLogisticsPage from '../pages/OrdersPage/ReverseLogisticsPage';
import OrderManual from '../pages/OrdersPage/OrderManual';
import LoginPage from '../pages/SignInSide/SignInSidePage';
import { useAuthContext } from '../utilities/context/AuthContextProvider';

const PublicRoutes = () => {
  const { authContext } = useAuthContext();
  return useRoutes([
    { path: '/activate', element: <ActivatePage /> },
    {
      path: '/seller',
      element: <Main rol="SELLER" />,
      children: [
        {
          path: '/seller/order/reverse',
          element: <ReverseLogisticsPage />,
        },
        {
          path: '/seller/order/manual',
          element: <OrderManual />,
        },
      ],
    },
    // { path: '/public/order/reverse', element: <ReverseLogisticsPage /> },
    { path: '/login', element: !authContext.token ? <LoginPage /> : <Navigate replace to="/" /> },
  ]);
};

export default PublicRoutes;
