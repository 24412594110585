import { Alert, Paper, Snackbar, TextField } from '@mui/material';
import { useTitle } from '../../components/Main';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { useFetchSettings } from '../../utilities/hooks/useFetchSettings';
import { saveSettings } from '../../services/iFlowServices';
import { useState } from 'react';

const useStyles = makeStyles({
  inputContainer: {
    width: '100%',
    marginBottom: '15px',
  },
  textInput: {
    width: '100%',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '60px',
  },
  linkButton: {
    width: '270px',
  },
  papper: {
    margin: '20px',
    padding: '30px',
    maxWidth: '700px',
    minWidth: '300px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
});

const ConfigPage = () => {
  useTitle().setTitle('Configuración');
  const classes = useStyles();

  const { settings, loading, updateInput } = useFetchSettings();
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState('');

  const handleInputChange = (event: any) => {
    updateInput(event.target.name, event.target.value);
  };

  const handleSaveSettings = () => {
    setIsSaving(true);
    saveSettings(settings)
      .then(() => {
        setSaveStatus('success');
      })
      .catch(() => {
        setSaveStatus('error');
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleCloseSaveToast = () => {
    setSaveStatus('');
  };

  const fields = [
    { name: 'APP_HOST', label: 'Application Host' },
    { name: 'TN_CLIENT_ID', label: 'Tiendanube Client Id' },
    { name: 'TN_CLIENT_SECRET', label: 'Tiendanube Client Secret' },
    { name: 'API_IFLOW_HOST', label: 'Api Iflow Host' },
    { name: 'TRACKING_IFLOW_HOST', label: 'Tracking Iflow Host' },
    { name: 'RATE_RESPONSE_NAME', label: 'Rate Response Name' },
    { name: 'RATE_RESPONSE_CODE', label: 'Rate Response Code' },
    { name: 'RATE_RESPONSE_TYPE', label: 'Rate Response Type' },
    { name: 'RATE_RESPONSE_REFERENCE', label: 'Rate Response Reference' },
  ];

  return (
    <>
      <Paper className={classes.papper}>
        <Snackbar
          open={saveStatus === 'success'}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={handleCloseSaveToast}
        >
          <Alert onClose={handleCloseSaveToast} severity="success" sx={{ width: '100%' }}>
            Configuración guardada
          </Alert>
        </Snackbar>
        <Snackbar
          open={saveStatus === 'error'}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={5000}
          onClose={handleCloseSaveToast}
        >
          <Alert onClose={handleCloseSaveToast} severity="error" sx={{ width: '100%' }}>
            Error al guardar la configuración
          </Alert>
        </Snackbar>
        <div className={classes.title}>Configuración</div>
        {!loading && (
          <div>
            {fields.map((f) => {
              return (
                <div className={classes.inputContainer} key={f.name}>
                  <TextField
                    name={f.name}
                    label={f.label}
                    variant="standard"
                    className={classes.textInput}
                    onChange={handleInputChange}
                    value={(settings as any)[f.name] || ''}
                  />
                </div>
              );
            })}

            <div className={classes.buttonContainer}>
              <LoadingButton
                loading={isSaving}
                variant="contained"
                className={classes.linkButton}
                disabled={isSaving || loading}
                onClick={handleSaveSettings}
              >
                {isSaving ? 'Guardando' : 'Guardar'}
              </LoadingButton>
            </div>
          </div>
        )}
      </Paper>
    </>
  );
};

export default ConfigPage;
