import Loading from '../../components/Loading';
import { useTitle } from '../../components/Main';
import { useFetchOrderByTnIdStoreId } from '../../utilities/hooks/useFetchOrderByTnIdStoreId';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TypographyRow from '../../components/DetailPage/TypographyRow';
import { Alert, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Listado from '../../components/Listado';
import ModalDialog from '../../components/ModalDialog';
import { saveReverseLogistic } from '../../services/iFlowServices';
import { useState } from 'react';
import { useAlertContext } from '../../utilities/context/AlertContextProvider';

const useStyles = makeStyles({
  card: {
    marginBottom: '10px',
  },
  webhooksContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  products: {
    marginTop: '20px',
  },
});

const ReverseLogisticsPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { handleSetMessage } = useAlertContext();
  const classes = useStyles();
  const { setTitle } = useTitle();

  const getUrlParam = (param: string) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(param) || '';
  };

  const tnId = getUrlParam('id');
  const store = getUrlParam('store');

  if (tnId) {
    setTitle(`Orden ${tnId}`);
  }

  const { data, loading, error } = useFetchOrderByTnIdStoreId(tnId, store);

  const { orderCreateAt, status, shippingAddress, products, trackingId, rate } = data;

  const columns: any = {
    columns: [
      { name: 'name', label: 'Nombre', minWidth: 90 },
      { name: 'price', label: 'Precio', minWidth: 90 },
      { name: 'productId', label: 'Id', minWidth: 90 },
      { name: 'quantity', label: 'Cantidad', minWidth: 90 },
      { name: 'sku', label: 'SKU', minWidth: 90 },
      { name: 'depth', label: 'Profundidad', minWidth: 90 },
      { name: 'height', label: 'Alto', minWidth: 90 },
      { name: 'width', label: 'Ancho', minWidth: 90 },
      { name: 'weight', label: 'Peso', minWidth: 90 },
    ],
  };

  const handleAccept = () => {
    setIsLoading(true);
    setButtonDisabled(true);
    saveReverseLogistic({
      id: tnId,
      store_id: store,
    })
      .then((response) => {
        handleSetMessage({
          message: `Logística Inversa Generada`,
          color: 'success',
          backHistory: true,
        });
      })
      .catch((error) => {
        handleSetMessage({
          message: 'Ha ocurrido un error al generar la logística inversa',
          color: 'error',
        });
        setButtonDisabled(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Loading loading={loading || isLoading}>
      {error && (
        <>
          <Alert severity="error">La logística inversa ya se encuentra generada para esta orden</Alert>
        </>
      )}
      {data && !error && (
        <Card sx={{ minWidth: 275 }} className={classes.card}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Datos Generales
            </Typography>
            <Grid container spacing={2}>
              {trackingId && <TypographyRow label="TrackingId" value={trackingId} />}
              <TypographyRow label="F Creación" value={orderCreateAt} />
              <TypographyRow label="Estado" value={status} />
              <TypographyRow label="Comercio" value={store} />
              {shippingAddress?.billingName && <TypographyRow label="Nombre" value={shippingAddress?.billingName} />}
              {shippingAddress?.customerEmail && <TypographyRow label="Email" value={shippingAddress?.customerEmail} />}
            </Grid>
          </CardContent>
        </Card>
      )}

      {shippingAddress && (
        <Card sx={{ minWidth: 275 }} className={classes.card}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Dirección de envío y datos del cliente
            </Typography>
            <Grid container spacing={2}>
              <TypographyRow label="Calle" value={shippingAddress?.billingAddress} />
              <TypographyRow label="Numero" value={shippingAddress?.billingNumber} />
              <TypographyRow label="Ciudad" value={shippingAddress?.billingCity} />
              <TypographyRow label="Provincia" value={shippingAddress?.billingProvince} />
              <TypographyRow label="C. Postal" value={shippingAddress?.billingZipcode} />
            </Grid>
          </CardContent>
        </Card>
      )}

      {products && products.length > 0 && (
        <Card sx={{ minWidth: 275 }} className={classes.card}>
          <CardContent>
            <Typography sx={{ fontSize: 14, marginBottom: '20px' }} color="text.secondary" gutterBottom>
              Artículos
            </Typography>
            <Grid container spacing={2} className={classes.products}>
              <br />
              <Listado loading={false} listadoColumnas={columns} rows={products || []} />
            </Grid>
          </CardContent>
        </Card>
      )}
      {rate && (
        <>
          <Card sx={{ minWidth: 275 }} className={classes.card}>
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Costo de la logística inversa a generar
              </Typography>
              <Grid container spacing={4}>
                <TypographyRow
                  label="Valor"
                  value={rate?.final_value?.toLocaleString('es-ar', {
                    style: 'currency',
                    currency: 'ARS',
                    minimumFractionDigits: 2,
                  })}
                />
              </Grid>
            </CardContent>
          </Card>
          <ModalDialog
            onAccept={handleAccept}
            buttonLabel="Generar Logística Inversa"
            dialogTitle="¿Esta seguro que desea generar la logística inversa?"
            buttonDisabled={buttonDisabled}
          />
        </>
      )}
    </Loading>
  );
};

export default ReverseLogisticsPage;
