import Loading from '../../components/Loading';
import { useTitle } from '../../components/Main';
import { useFetchOrderById } from '../../utilities/hooks/useFetchOrderById';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TypographyRow from '../../components/DetailPage/TypographyRow';
import { Box, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Listado from '../../components/Listado';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { sendShippingToIflow, updateOrder } from '../../services/iFlowServices';
import { useState } from 'react';
import { useAlertContext } from '../../utilities/context/AlertContextProvider';
import ModalDialog from '../../components/ModalDialog';
import TextField from '@mui/material/TextField';
import Input from '../../components/Forms/Input/Input';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  card: {
    marginBottom: '10px',
  },
  webhooksContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  products: {
    marginTop: '20px',
  },
  printButton: {
    marginRight: '10px!important',
  },
  button: {
    width: '50%',
    margin: '0 auto!important',
    display: 'flex!important',
  },
});

const OrderEditPage = () => {
  const classes = useStyles();
  const { setTitle } = useTitle();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { handleSetMessage } = useAlertContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const { id: _id } = useParams();

  setTitle(`Orden`);

  const { data, loading } = useFetchOrderById();

  const { id, orderCreateAt, status, storeId, created, paid, packed, fulfill, cancelled, shippingAddress, products, trackingId, events, printUrl } =
    data;

  if (data) {
    setTitle(`Orden ${id}`);
  }

  const columns: any = {
    columns: [
      { name: 'name', label: 'Nombre', minWidth: 90 },
      { name: 'price', label: 'Precio', minWidth: 90 },
      { name: 'productId', label: 'Id', minWidth: 90 },
      { name: 'quantity', label: 'Cantidad', minWidth: 90 },
      { name: 'sku', label: 'SKU', minWidth: 90 },
      { name: 'depth', label: 'Profundidad', minWidth: 90 },
      { name: 'height', label: 'Alto', minWidth: 90 },
      { name: 'width', label: 'Ancho', minWidth: 90 },
      { name: 'weight', label: 'Peso', minWidth: 90 },
    ],
  };

  const handlePrint = () => {
    window.open(printUrl, '_blank', 'noopener,noreferrer');
  };

  const onSubmit = async (data: any) => {
    try {
      console.log(data);
      const response = await updateOrder(_id, data);
      handleSetMessage({
        message: `La orden ha sido modificada`,
        color: 'success',
      });
    } catch (e) {
      handleSetMessage({
        message: 'Ha ocurrido un error al modificar la orden',
        color: 'error',
      });
    }
  };

  const handleGenerateShipping = () => {
    setIsLoading(true);
    setButtonDisabled(true);
    sendShippingToIflow({
      id: id,
      store_id: storeId,
    })
      .then((response) => {
        if (response.status === 201) {
          const trackingId = response?.results?.tracking_id;
          handleSetMessage({
            message: `Orden ${trackingId} Generada`,
            color: 'success',
            backHistory: true,
          });
        } else {
          let message = 'Ha ocurrido un error al generar la Orden';
          if (response?.message?.errors) {
            const keys = Object.keys(response?.message?.errors);
            for (let i = 0; i < keys.length; i++) {
              message += response?.message?.errors[keys[i]];
            }
          }
          handleSetMessage({
            message: message,
            color: 'error',
          });
          setButtonDisabled(false);
        }
      })
      .catch((error) => {
        handleSetMessage({
          message: 'Ha ocurrido un error al generar la Orden',
          color: 'error',
        });
        setButtonDisabled(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <Loading loading={isLoading || loading}>
      {printUrl && (
        <Button variant="outlined" startIcon={<LocalPrintshopIcon />} onClick={handlePrint} className={classes.printButton}>
          Imprimir Etiqueta
        </Button>
      )}
      {(status === 'NOT_PROCESSED' || status === 'CREATED') && (
        <ModalDialog
          onAccept={handleGenerateShipping}
          buttonLabel="Generar Order"
          dialogTitle="¿Esta seguro que desea generar el envío en iFlow?"
          buttonDisabled={buttonDisabled}
        />
      )}

      <Card sx={{ minWidth: 275 }} className={classes.card}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Datos Generales
          </Typography>
          <Grid container spacing={2}>
            {trackingId && <TypographyRow label="TrackingId" value={trackingId} />}
            <TypographyRow label="F Creación" value={orderCreateAt} />
            <TypographyRow label="Estado" value={status} />
            <TypographyRow label="Comercio" value={storeId} />
            {shippingAddress?.billingName && <TypographyRow label="Nombre" value={shippingAddress?.billingName} />}
            {shippingAddress?.customerEmail && <TypographyRow label="Email" value={shippingAddress?.customerEmail} />}
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ minWidth: 275 }} className={classes.card}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            Estados
          </Typography>
          <Grid container spacing={2}>
            <TypographyRow label="Creado" value={created} />
            <TypographyRow label="Pagado" value={paid} />
            <TypographyRow label="Packed" value={packed} />
            <TypographyRow label="Fulfill" value={fulfill} />
            <TypographyRow label="Cancelado" value={cancelled} />
          </Grid>
        </CardContent>
      </Card>
      {products && products.length > 0 && (
        <Card sx={{ minWidth: 275 }} className={classes.card}>
          <CardContent>
            <Typography sx={{ fontSize: 14, marginBottom: '20px' }} color="text.secondary" gutterBottom>
              Artículos
            </Typography>
            <Grid container spacing={2} className={classes.products}>
              <br />
              <Listado loading={false} listadoColumnas={columns} rows={products || []} />
            </Grid>
          </CardContent>
        </Card>
      )}
      {shippingAddress && trackingId && (
        <Card sx={{ minWidth: 275 }} className={classes.card}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Dirección de envío y datos del cliente
            </Typography>
            <Grid container spacing={2}>
              <TypographyRow label="Calle" value={shippingAddress?.billingAddress} />
              <TypographyRow label="Numero" value={shippingAddress?.billingNumber} />
              <TypographyRow label="Ciudad" value={shippingAddress?.billingCity} />
              <TypographyRow label="Provincia" value={shippingAddress?.billingProvince} />
              <TypographyRow label="C. Postal" value={shippingAddress?.billingZipcode} />
            </Grid>
          </CardContent>
        </Card>
      )}
      {!trackingId && (
        <Card sx={{ minWidth: 275 }} className={classes.card}>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Dirección de envío
            </Typography>
            <Grid container spacing={2} xs={12}>
              <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%' }}>
                <Input
                  control={control}
                  name="billingAddress"
                  defaultValue={shippingAddress?.billingAddress}
                  label="Calle"
                  type="text"
                  errors={errors}
                />
                <Input
                  control={control}
                  name="billingNumber"
                  defaultValue={shippingAddress?.billingNumber}
                  label="Numero"
                  type="number"
                  errors={errors}
                />
                <Input control={control} name="billingCity" defaultValue={shippingAddress?.billingCity} label="Ciudad" type="text" errors={errors} />
                <Input
                  control={control}
                  name="billingProvince"
                  defaultValue={shippingAddress?.billingProvince}
                  label="Provincia"
                  type="text"
                  errors={errors}
                />
                <Input
                  control={control}
                  name="billingZipcode"
                  defaultValue={shippingAddress?.billingZipcode}
                  label="Codigo Postal"
                  type="number"
                  errors={errors}
                />
                <Button type="submit" variant="contained" className={classes.button}>
                  Guardar
                </Button>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Loading>
  );
};

export default OrderEditPage;
