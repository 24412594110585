import { useEffect, useState } from 'react';
import { getSettings } from '../../../services/iFlowServices';

export const useFetchSettings = () => {
  const [settings, setSettings] = useState({
    APP_HOST: '',
    TN_CLIENT_ID: '',
    TN_CLIENT_SECRET: '',
    API_IFLOW_HOST: '',
    TRACKING_IFLOW_HOST: '',
    RATE_RESPONSE_NAME: '',
    RATE_RESPONSE_CODE: '',
    RATE_RESPONSE_TYPE: '',
    RATE_RESPONSE_REFERENCE: '',
  });
  const [loading, setLoading] = useState(true);

  const fetchSettings = async () => {
    setLoading(true);
    const response = await getSettings();
    if (response) {
      setSettings(response);
    }

    setLoading(false);
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  const updateInput = (name: string, value: string) => {
    setSettings({ ...settings, [name]: value });
  };

  return { settings, loading, updateInput };
};
