import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { makeStyles } from '@mui/styles';
import { useAuthContext } from '../../utilities/context/AuthContextProvider';
import { Link } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DashboardIcon from '@mui/icons-material/Dashboard';

const useStyles = makeStyles({
  listItem: {
    color: '#000000',
  },
});

interface Props {
  rol?: string;
}

function Menu({ rol }: Props) {
  const classes = useStyles();
  const { logoutUser } = useAuthContext();

  const openNewWindow = () => {
    window.open(
      'https://tracking.iflow21.com/#/login',
      '_blank', // <- This is what makes it open in a new window.
    );
  };

  const sellerRol = (
    <>
      <List>
        <ListItem key="logout" disablePadding onClick={openNewWindow}>
          <ListItemButton>
            <OpenInNewIcon>
              <LogoutIcon />
            </OpenInNewIcon>
            <ListItemText primary="Tracking iFlow" />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );

  const adminRol = (
    <>
      <List>
        <ListItem key="dashboard" disablePadding component={Link} to="/" className={classes.listItem}>
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </ListItem>
        <ListItem key="orders" disablePadding component={Link} to="/orders" className={classes.listItem}>
          <ListItemButton>
            <ListItemIcon>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Órdenes" />
          </ListItemButton>
        </ListItem>
        <ListItem key="users" disablePadding component={Link} to="/users" className={classes.listItem}>
          <ListItemButton>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItemButton>
        </ListItem>
        <ListItem key="sellers" disablePadding component={Link} to="/sellers" className={classes.listItem}>
          <ListItemButton>
            <ListItemIcon>
              <StorefrontIcon />
            </ListItemIcon>
            <ListItemText primary="Vendedores" />
          </ListItemButton>
        </ListItem>
        <ListItem key="status" disablePadding component={Link} to="/status" className={classes.listItem}>
          <ListItemButton>
            <ListItemIcon>
              <LinearScaleIcon />
            </ListItemIcon>
            <ListItemText primary="Estados" />
          </ListItemButton>
        </ListItem>
        <ListItem key="config" disablePadding component={Link} to="/config" className={classes.listItem}>
          <ListItemButton>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Configuración" />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key="logout" disablePadding onClick={() => logoutUser()}>
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Salir" />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );

  if (rol === 'SELLER') {
    return sellerRol;
  }
  return adminRol;
}

export default Menu;
