export const textFieldStyle = {
  '.MuiOutlinedInput-input': {
    color: '#c3c3c3',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgb(147, 193, 30, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgb(147, 193, 30, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgb(147, 193, 30, 0.5)',
    },
  },
  '.MuiFormLabel-root': {
    color: 'rgb(147, 193, 30, 0.5)',
    '&.Mui-focused': {
      color: 'rgb(147, 193, 30)',
    },
  },
}

export const buttonStyle = {
    backgroundColor: 'rgb(147, 193, 30, 0.5)',
    '&:hover': {
      backgroundColor: 'rgb(147, 193, 30, 0.8)',
    },
    '&.Mui-disabled': {
      backgroundColor: 'rgb(147, 193, 30, 0.4)',
    },
  }
