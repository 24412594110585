/* eslint-disable quotes */
import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ListadoColumnas, Column } from './interfaces';
interface Props {
  listadoColumnas: ListadoColumnas;
}

export default function ListadoHeader(props: Props) {
  const { listadoColumnas } = props;
  const columns = listadoColumnas.columns;

  return (
    <TableHead>
      <TableRow>
        {listadoColumnas.actions && (listadoColumnas.actions.edit || listadoColumnas.actions.delete || listadoColumnas.actions.view) && (
          <TableCell key={'actions'} style={{ minWidth: '20px' }}>
            Acciones
          </TableCell>
        )}
        {columns.map((column: Column) => (
          <TableCell key={column.name} align={column.align} style={{ minWidth: column.minWidth }}>
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
