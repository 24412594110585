import Loading from '../../components/Loading';
import { useTitle } from '../../components/Main';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ModalDialog from '../../components/ModalDialog';
import { useEffect, useState } from 'react';
import { useFetchStatusById } from '../../utilities/hooks/useFetchStatusById';
import { saveStatus, updateStatus } from '../../services/iFlowServices';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlertContext } from '../../utilities/context/AlertContextProvider';

const useStyles = makeStyles({
  card: {
    marginBottom: '10px',
    padding: '40px',
  },
  input: { m: 1, width: '50%' },
});

const StatusDetailPage = () => {
  const classes = useStyles();
  const { setTitle } = useTitle();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [form, setForm] = useState({ iflowStatus: '', tiendaNubeStatus: '', finalState: false });
  const { handleSetMessage } = useAlertContext();
  const navigate = useNavigate();

  const { id } = useParams();
  if (id) {
    setTitle(`Editar Estado`);
  } else {
    setTitle(`Nuevo Estado`);
  }

  const { data, loading } = useFetchStatusById();

  useEffect(() => {
    if (id) {
      const { iflowStatus, tiendaNubeStatus, finalState } = data;
      console.log('🚀 ~ file: StatusDetailPage.tsx:45 ~ useEffect ~ finalState', finalState);
      setForm({
        iflowStatus,
        tiendaNubeStatus,
        finalState,
      });
    }
  }, [data, id]);

  const handleUpdateStatus = (id: string) => {
    updateStatus(id, form)
      .then((response) => {
        handleSetMessage({
          message: `Estado modificado`,
          color: 'success',
        });
      })
      .catch((error) => {
        handleSetMessage({
          message: 'Ha ocurrido un error al modificar el estado',
          color: 'error',
        });
        setButtonDisabled(false);
      })
      .finally(() => {
        navigate('/status');
      });
  };
  const handleSaveStatus = () => {
    saveStatus(form)
      .then((response) => {
        handleSetMessage({
          message: `Estado creado`,
          color: 'success',
        });
      })
      .catch((error) => {
        handleSetMessage({
          message: 'Ha ocurrido un error al crear el estado',
          color: 'error',
        });
        setButtonDisabled(false);
      })
      .finally(() => {
        navigate('/status');
      });
  };

  const handleAccept = () => {
    console.log(form);
    setButtonDisabled(true);
    if (id) {
      handleUpdateStatus(id);
    } else {
      handleSaveStatus();
    }
  };

  const handleChange = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleClick = () => {
    setForm({
      ...form,
      finalState: !form.finalState,
    });
  };

  return (
    <Loading loading={loading}>
      <Card sx={{ minWidth: 275 }} className={classes.card}>
        <CardContent>
          <div>
            <TextField
              id="iflow-status"
              name="iflowStatus"
              value={form.iflowStatus}
              onChange={handleChange}
              label="Estado iFlow"
              sx={{ m: 1, width: '50%' }}
            />
          </div>
          <div>
            <TextField
              id="tn-status"
              name="tiendaNubeStatus"
              value={form.tiendaNubeStatus}
              onChange={handleChange}
              label="Estado Tienda Nube"
              sx={{ m: 1, width: '50%' }}
            />
          </div>
          <div>
            <FormGroup>
              <FormControlLabel control={<Switch name="finalState" checked={form.finalState} onClick={handleClick} />} label="Estado Final" />
            </FormGroup>
          </div>
          <ModalDialog
            onAccept={handleAccept}
            buttonLabel="Guardar"
            dialogTitle="¿Esta seguro que desea guardar el mapeo de estados?"
            buttonDisabled={buttonDisabled}
          />
        </CardContent>
      </Card>
    </Loading>
  );
};

export default StatusDetailPage;
