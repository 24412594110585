import React from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

interface Props {
  children: any;
  loading: boolean;
}
function Loading({ children, loading }: Props) {
  const classes = useStyles();
  return (
    <>
      {loading && (
        <div className={classes.container}>
          <CircularProgress />
        </div>
      )}
      {!loading && children}
    </>
  );
}

export default Loading;
