/* eslint-disable quotes */
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Filter } from './interfaces';
import Grid from '@mui/material/Grid';
import ListadoFilter from './ListadoFilter';
import TypographyRow from '../DetailPage/TypographyRow';
const useStyles = makeStyles({
  container: {
    padding: 20,
  },
});

interface Props {
  filters: Filter[];
  onHandleChange: (key: string, value: string | number) => void;
}

export default function ListadoFilters(props: Props) {
  const classes = useStyles();
  const { filters, onHandleChange } = props;

  return (
    <Grid className={classes.container}>
      <TypographyRow label="Filtros" />
      {filters && filters.map((filter) => <ListadoFilter filter={filter} key={filter.name} onHandleChange={onHandleChange} />)}
    </Grid>
  );
}
