/* eslint-disable quotes */
import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ListadoColumnas, Column, Row } from './interfaces';
import LinearProgress from '@mui/material/LinearProgress';
import ListadoBodyCell from './ListadoBodyCell';
import ListadoBodyActions from './ListadoBodyActions';

interface Props {
  listadoColumnas: ListadoColumnas;
  rows: Row[];
  loading: boolean;
}

export default function ListadoBody(props: Props) {
  const { listadoColumnas, rows, loading } = props;
  const columns = listadoColumnas.columns;
  return (
    <TableBody>
      {loading && (
        <TableRow hover tabIndex={-1} component="tr">
          <TableCell colSpan={columns.length}>
            <LinearProgress />
          </TableCell>
        </TableRow>
      )}

      {!loading &&
        rows.length > 0 &&
        rows.map((row: any) => {
          return (
            <TableRow hover tabIndex={-1} key={row._id}>
              {listadoColumnas.actions && <ListadoBodyActions actions={listadoColumnas.actions} row={row} />}

              {columns.map((column: Column, i: any) => {
                //const value = row.receiver_name;
                return <ListadoBodyCell key={i} column={column} row={row} />;
              })}
            </TableRow>
          );
        })}
      {!loading && rows.length === 0 && true && (
        <TableRow hover tabIndex={-1}>
          <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
            No hay resultados
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}
