import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import Copyright from '../../components/Copyright/Copyright';
import Input from '../../components/Forms/Input/Input';
import { login } from '../../services/iFlowServices';
import { useAlertContext } from '../../utilities/context/AlertContextProvider';
import { useAuthContext } from '../../utilities/context/AuthContextProvider';

function SignInSide() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const { handleSetMessage } = useAlertContext();
  const { loginUser } = useAuthContext();

  const onSubmit = async (data: any) => {
    try {
      const response = await login(data);
      const token = response?.access_token;
      const username = response?.username;
      loginUser({ token, username });
      handleSetMessage({
        message: `Bienvenido ${username}`,
        color: 'success',
      });
    } catch (e) {
      handleSetMessage({
        message: 'Usuario y/o contraseña incorrectos',
        color: 'error',
      });
    }
  };
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/back.png)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: '#f16522' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 1 }}>
            <Input control={control} name="username" label="Usuario" type="text" errors={errors} />

            <Input control={control} name="password" label="Contraseña" type="password" errors={errors} />

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>

            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SignInSide;
