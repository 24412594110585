import { useEffect, useState } from 'react';
import { RequestOptionsListado } from '../../../components/Listado/interfaces';
import { getDashboardOrders, getDashboardSellers } from '../../../services/iFlowServices';

export const useFetchDashboard = () => {
  const [response, setResponse] = useState<any>();
  const [loading, setLoading] = useState(true);

  const fetchOrders = async (options?: RequestOptionsListado) => {
    setLoading(true);
    const order = await getDashboardOrders();
    const seller = await getDashboardSellers();
    setResponse({ order, seller });
    setLoading(false);
  };
  useEffect(() => {
    fetchOrders();
  }, []);

  return { response, loading };
};
