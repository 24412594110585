import React from 'react';
// import { useHistory } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { UserAlert } from '../../interfaces/interfaces';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  snackbar: {
    width: '100%',
  },
  alert: {
    width: '50%',
    margin: '0 auto',
  },
});

interface Props {
  closeUserAlert: () => void;
  userAlert: UserAlert;
}
function SnackbarCustom({ closeUserAlert, userAlert }: Props) {
  const classes = useStyles();
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
  };
  return (
    <>
      {userAlert.open && (
        <Snackbar className={classes.snackbar} open={userAlert.open} autoHideDuration={userAlert.duration || 1000} onClose={handleClose}>
          <Alert className={classes.alert} severity={userAlert.color ? userAlert.color : undefined}>
            {userAlert.message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default SnackbarCustom;
